import React from 'react';

import { AnalyticsBrowser, type AnalyticsSnippet } from '@segment/analytics-next';

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

export const analytics = new AnalyticsBrowser();

export default function useLoadSegment({ writeKey }: { writeKey: string }) {
  React.useEffect(() => {
    const loadSegment = async () => {
      analytics.load({ writeKey }).catch((error) => console.warn('Segment failed to load ', error));
    };

    if (writeKey) loadSegment();
  }, [writeKey]);
}
